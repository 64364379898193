import {postRequest, putRequest} from "./utility";
import {getInstance} from "@/auth/auth0-plugin";
import router from "@/router";
import accessList from '@/config/userAccess.json'
import { authLogin } from 'sw-studio-core/src/utils/auth';

export function createPassword(store, password) {
    const url = window.APP_CONFIG.URL_API + 'users/' + store.state.user.token + "/create-password"
    return putRequest(
        url,
        {password: password},
        () => authLogin(
            store.state.user.email,
            password,
            () => {
                router.push({name: 'institutions.index'})
            },
            (error) => {
                store.commit('user/loginFailure', error)
                router.push({name: 'login'});
            }),
        (error) => {
            store.commit('user/passwordCreationFailure', error)
        }
    )
}

export function updatePassword(passwords) {
    console.log('Password édité avec : ', passwords) //todo: Finish it after merge
}

/**
 * Validate the account of the user.
 */
export function validateAccount(element, email, password) {
    const url = process.env.VUE_APP_URL_VALIDATION;
    const data = {email, password};
    const successCallback = () => {
        element.$router.push({name: "home"});
    };
    const errorCallback = (e) => {
        console.error(e);
        element.$router.push({name: "notAllowed"});
    };
    postRequest(element.axios, url, data, successCallback, errorCallback);
}

/**
 * Gets the role of the user
 */
export function getRole(user) {
    return user["https://superwyze-manager.com/role"];
}

/**
 * Check if the user has the rights of an admin
 */
export function isAdmin(user) {
    if (!user) {
        user = getUser();
    }
    return user ? getRole(user) === "ADMIN" : false;
}

/**
 * Check if the user has the rights of an operator
 */
export function isOperator(user) {
    if (!user) {
        user = getUser();
    }
    const availabledRoles = ["ADMIN", "OPERATOR"];
    return user ? availabledRoles.indexOf(getRole(user)) >= 0 : false;
}

/**
 * Gets the authenticate user
 */
export function getUser() {
    const authService = getInstance();
    return authService.user;
}

export function getUserAccessList(role = 'UTILISATEUR') {
    return accessList[role];
}
