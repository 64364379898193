// Ce fichier contient des fonctions permettant la communication
// au backend

import $ from "jquery";
import axios from "axios";
// import router from "@/router";


/**
 * Interceptor Axios
 */

// axios.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         if (error.code === 'ERR_CANCELED') {
//             return Promise.reject(error);
//         }else if (error.response.status === 401 ) {
//             router.push({name: 'login'});
//         } else if (error.response.status === 403) {
//             router.push({name: 'institutions.index'});
//         }
//         return Promise.reject(error);
//     }
// );

export function downloadFile(data, name, type) {
    const url = URL.createObjectURL(
        new Blob([data], {
            type: type,
        })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
}

// import { store } from "../store";

export function loading(isLoading) {
    if (isLoading) {
        $("body:not(.loading)").addClass("loading");
    } else {
        $("body").removeClass("loading");
    }
}

/**
 * Requête GET
 * @param {string} url url associé au GET
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param headers
 * @param extraDataAxios par exemple le dataType attendu
 */
export function getRequest(
    url,
    successCallback,
    errorCallback,
    headers,
    extraDataAxios,
) {
    return axiosRequest(
        "GET",
        url,
        null,
        successCallback,
        errorCallback,
        headers,
        extraDataAxios
    );
}

/**
 * Requête POST
 * @param {string} url url associé au GET
 * @param {object} data données de la requête
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param headers
 */
export function postRequest(
    url,
    data,
    successCallback,
    errorCallback,
    headers,
    additionnalData,
    signal
) {
    axiosRequest(
        "POST",
        url,
        data,
        successCallback,
        errorCallback,
        headers,
        additionnalData,
        signal
    );
}

/**
 * Requête PUT
 * @param {string} url url associé au GET
 * @param {object} data données de la requête
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param headers
 */
export function putRequest(
    url,
    data,
    successCallback,
    errorCallback,
    headers,
) {
    return axiosRequest(
        "PUT",
        url,
        data,
        successCallback,
        errorCallback,
        headers,
    );
}

/**
 * Requête DELETE
 * @param {string} url url associé au GET
 * @param {object} data données de la requête
 * @param {function} successCallback fonction à exécuter en cas de succès
 * @param {function} errorCallback fonction à exécuter en cas d'échec
 * @param headers
 */
export function deleteRequest(
    url,
    data,
    successCallback,
    errorCallback,
    headers,
) {
    axiosRequest(
        "DELETE",
        url,
        data,
        successCallback,
        errorCallback,
        headers,
    );
}

/**
 * Lancement de la requête
 */
async function axiosRequest(
    method,
    url,
    data,
    successCallback,
    errorCallback,
    headers,
    extraDataAxios,
    signal
) {
    if (!headers) headers = {};
    if (data == null) data = {};
    if (!extraDataAxios) extraDataAxios = {};
    loading(true);

    try {
        const accessToken = localStorage.getItem("SWSTUDIO_ACCESS_TOKEN");
        if (accessToken) {
            headers["Authorization"] = "Bearer " + accessToken;
        }
    } catch (error) {
        // error thrown
    }

    const config = {
        method: method,
        url: url,
        headers: headers,
        data: data,
        ...extraDataAxios,
    }

    if (signal) {
        config.signal = signal;
    }

    // lancement de la requête
    return axios(config)
        .then((response) => {
            loading(false);
            // la requête a aboutit avec succès
            if (successCallback) {
                successCallback(response);
            }
        })
        .catch(function (error) {
            loading(false);
            // une erreur s'est produite
            if (errorCallback) {
                errorCallback(error);
            }
        });
}
