<template>
    <div :class="docModalClass" ref="modal">
        <div class="doc-icon-modal" @click="hideDocModal">
          <inline-svg
            src="/menu/info_default.svg"/>
        </div>
        <div class="doc-modal-content">
            <div class="doc-modal-title">
                {{ $t('documentation.modalTitle') }}
            </div>
            <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
            <div class="download-doc-button">
                <b-button size="m" class="toggle-navbar-button" @click="downloadDocumentation()">
                    {{ $t('documentation.modalButton') }} <b-icon icon="file-earmark-text" />
                </b-button>
            </div>
            <div class="doc-modal-footter">
                {{ $t('documentation.modalFooter') }}<a href="mailto:support@superwyze.com" class="doc-modal-email">support@superwyze.com</a>
            </div>
        </div>
    </div>

</template>

<script>
import { downloadDocumentation } from '@/services/download';
import store from "@/store";
import InlineSvg from "vue-inline-svg";


export default {
    components: {
        InlineSvg
    },
    data() {
        return {
            errorMessage: '',
            title: 'test',
            isVisible: false,
            userRole: store.state.institution?.userRole,

        }
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        displayModal: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
    document.addEventListener("click", this.handleClickOutside);
},
beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
},
    methods: {
        handleClickOutside(event) {
        if(!this.isVisible && this.displayModal) {
            this.isVisible = true;
        }
        else if (this.isVisible && this.displayModal && !this.$refs?.modal?.classList?.contains("hide-modal") && !this.$refs?.modal?.contains(event.target)) {
            this.hideDocModal();
        }
    },
        onConfirmButtonClick() {
            try {
                
            } catch (error) {
                this.errorMessage = error;
            }
        },
        hideDocModal(){
            this.$emit('close');
            this.isVisible = false;
        },
        downloadDocumentation() {
            downloadDocumentation(this.userRole);
        }
        
    },
    computed: {
        docModalClass() {
            return this.displayModal ? 'sw-doc-modal show-modal' : 'sw-doc-modal hide-modal';
        }
    }, 
    watch: {
    }
}
</script>

<style lang="scss" scoped>
.sw-doc-modal {
    font-weight: bold !important;
    background-color: white;
    position: absolute;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    transform: translate(-330px, -40px);
    padding: 5px;
    border-radius: 15px;
}
.hide-modal {
    display: none;
}
.show-modal {
    display: block;
}
.doc-icon-modal {
    float: right;
    cursor: pointer;
    > svg {
        display: inline-block;
        stroke: var(--sw-secondary-green-dark) !important;
        
  }
}
.doc-modal-content {
    width: 350px;
    height: 170px;
    padding: 10px 30px 10px 30px;
}
.doc-modal-title {
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 15px;
}
.download-doc-button {
    display: flex;
    justify-content: center;
    button {
        font-weight: bold !important;
        border-radius: 8px;
    }
}
.doc-modal-footter {
    margin-top: 15px;
    text-align: center;
    a {
        text-decoration: none;
    }
}
.doc-modal-email {
    color: var(--sw-secondary-green-dark);
}
</style>