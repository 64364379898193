import { postRequest } from "./utility";

const onLoginSuccess = (response, successCallback) => {
    const {data} = response;

    localStorage.setItem("SWSTUDIO_ACCESS_TOKEN", data.access_token);
    successCallback(data);
};

export function authLogin(username, password, successCallback, errorCallback, refreshToken = true) {
    const url = window.APP_CONFIG.URL_API + "login";
    const data = {username, password};
    if(refreshToken) {
        localStorage.removeItem("SWSTUDIO_ACCESS_TOKEN");
    }
    return postRequest(
        url,
        data,
        (response) => onLoginSuccess(response, successCallback),
        (error) => errorCallback(error)
    );
}