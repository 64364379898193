const themeModule = {
    namespaced: true,
    state: {
      selectedTheme: localStorage.getItem('theme') || 'default'
    },
    mutations: {
        setTheme(state, theme) {
            document.documentElement.classList.remove('default', state.selectedTheme);
            state.selectedTheme = theme;
            localStorage.setItem('theme', theme);
            document.documentElement.classList.add(theme);
        }
    },
    actions: {
        setTheme({ commit }, theme) {
            commit('setTheme', theme);
        },
        initializeTheme({ commit }) {
            const savedTheme = localStorage.getItem('theme') || 'default';
            commit('setTheme', savedTheme);
        }
    }
}
  
export default themeModule;