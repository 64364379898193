import {getRequest, postRequest} from "@/utils/utility";
import {now} from "lodash";
import moment from "moment";

let genericUrl = window.APP_CONFIG.URL_API + 'institutions/'


export function lightDownload(data, fileName) {
    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = fileName + '.xlsx';
    downloadLink.click();
    window.URL.revokeObjectURL(blobUrl);
}

export function lightPDFDownload(data, filename) {
    // Create a Blob URL for the response data
    const blob = new Blob([data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename + ".pdf"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove link and revoke URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}

export function downloadEntityInInstitution(searchTerm,entity, institutionId, errorCallback = (error) => {
    console.error(error)
}, fileNamePrefix='') {
    getRequest(genericUrl + institutionId + '/'+ entity +'/download?searchTerm=' + searchTerm,
        (response) => {
        if(!fileNamePrefix) {
            fileNamePrefix = entity;
        }
        lightDownload(response.data, fileNamePrefix +'_'+ moment(now()).format('YYYY_MM_DD'))
        },
        errorCallback,
        '',
        {responseType: 'blob'});
}

export function downloadSensorFromLocationsWithFilters(
    institutionId,
    filters,
    errorCallback = (error) => {
    console.error(error)
}) {
    postRequest(
        genericUrl + institutionId + '/sensors/locations-list',
        filters,
        (response) => {
            lightDownload(response.data,  'positions_'+ moment(now()).format('YYYY_MM_DD'))
        },
        errorCallback,
        '',
        {responseType: 'blob'});
}

export function downloadLevelGateways(
    institutionId,
    buildingId,
    levelId,
    errorCallback = (error) => {
        console.error(error)
    }) {
    getRequest(
        genericUrl + institutionId + '/buildings/'+buildingId+'/levels/' + levelId + '/gateways/download',
        (response) => {
            lightDownload(response.data,  'passarelles_'+ moment(now()).format('YYYY_MM_DD_h_m'))
        },
        errorCallback,
        '',
        {responseType: 'blob'});
}

export function downloadDocumentation(
    userRole,
    errorCallback = () => {
    },
) {
    const fileName = 'doc-'+userRole;
    let url = window.APP_CONFIG.URL_API + 'download/documentation/' + fileName;
    getRequest(
        url,
        (response) => {
            lightPDFDownload(response.data,  fileName)
        },
        (error) => errorCallback(error),
        '',
        {responseType: 'blob'}
    )
}